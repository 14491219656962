<template>
    <div id="float-agent">
        <img src="@/assets/float-agent/13.png" v-on:click="showAgent()" style="z-index: 900;">
    </div>
</template>

<script setup>
import emitter from "./UniqEvent";
import {onMounted} from "vue";

function showAgent() {
    emitter.emit('show-agent');
}

onMounted(() => {
    let ua = navigator.userAgent;
    if (!ua.includes('iPhone') && !ua.includes('Android')) {
        let node = document.querySelector('#float-agent');
        node.style.setProperty('--kf-img-width', '5rem');
        node.style.setProperty('--kf-fixed-right', 'calc(50vw - var(--max-width)/2)');
    }
});
</script>

<style scoped>
#float-agent {
    --kf-img-width: 4rem;
    --kf-fixed-right: 0.5rem;
    position: fixed;
    right: var(--kf-fixed-right);
    top: calc(50% - 10rem);
    z-index: 10;
}

#float-agent img {
    width: var(--kf-img-width);
}
</style>