<template>
    <div id="gift">
        <div id="a"></div>
        <div id="b">
            <div>
                <div>安装稳定升级版</div>
                <div>领取<span style="color: red;font-weight: bold;">82.28</span>元彩金</div>
            </div>
            <div class="bt" @click="download" style="user-select: none;">是</div>
            <div class="bt" @click="close()">否</div>
        </div>
    </div>
</template>

<script>
    import emitter from "./UniqEvent";

    export default {
        name: "GiftComp",
        beforeCreate() {
            emitter.on('show-gift', function () {
                document.getElementById('gift').style.display = 'flex';
            });
            emitter.on('close-gift', function () {
                document.getElementById('gift').style.display = 'none';
            });
        },
        methods: {
            close: function () {
                document.getElementById('gift').style.display = 'none';
            },
            show: function () {

            },
            download: function () {
                emitter.emit('download-app');
            }
        }
    }
</script>

<style scoped>
    #gift {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    #a {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: .3;
        display: grid;
        justify-content: center;
        align-items: center;
        z-index: 200;
    }

    #b {
        width: 14rem;
        border-radius: 1rem;
        background-color: lightgray;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;
        z-index: 300;
        color: black;
    }

    #b > div:nth-child(1) {
        grid-column: 1/3;
        border-bottom: 1px solid lightgray;
        line-height: 1.5rem;
        padding-top: 1.2rem;
        letter-spacing: .2rem;
    }

    #b > div:nth-child(2) {
        border-right: 1px solid lightgray;
        color: dodgerblue;
    }

    .bt {
        padding-top: .6rem;
        padding-bottom: .6rem;
    }


</style>